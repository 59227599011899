import { auth } from "auth"
import { v1 } from "backoffice-api"
import { useModal, useRouteParamNumber } from "hooks"
import { Loader } from "materia"
import { createPlaythrough } from "src/bonzai/createPlaythrough"
import { usePickText } from "src/i18n/usePickText"
import { useIframeMessages } from "src/tools/useIframeMessages"
import { ViewerModal } from "ui/exports/portal"
import { QueryBoundary } from "utility-components"

type WebGamesModalLoaderProps = {
  closeRoute: string
}
export const WebGamesModalLoader = ({
  closeRoute,
}: WebGamesModalLoaderProps) => {
  const productId = useRouteParamNumber("productId")

  const { onClose } = useModal(closeRoute, () => {
    createPlaythrough(productId, {
      mutate: false,
      invalidate: true,
      invalidateDependencies: true,
    })
  })

  return (
    <ViewerModal onClose={onClose}>
      <QueryBoundary fallback={<Loader />}>
        <Load productId={productId} onClose={onClose} />
      </QueryBoundary>
    </ViewerModal>
  )
}

type LoadProps = {
  productId: number
  onClose: () => void
}
const Load = ({ productId, onClose }: LoadProps) => {
  const product = v1.getProduct.useQuery([productId])

  useIframeMessages({
    onClose,
    targetUrl: product.content_link,
  })

  const linkWithSession = auth.getLinkWithActiveSession.useQuery(
    [{ url: product.content_link }],
    { cacheTime: 0 }
  )

  const pickText = usePickText()

  return (
    <ViewerModal.FullScreen
      src={linkWithSession}
      headerText={pickText(product.titles)}
      onClose={onClose}
    />
  )
}
