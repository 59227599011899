import bowser from "bowser"

type DevicePlatform = "iOS" | "Android" | "Windows" | "MacOS"

type PlatformInformation =
  | {
      platform: undefined
      isLauncherPlatform: false
      isAppPlatform: false
      isWebglPlatform: false
    }
  | {
      platform: DevicePlatform
      isLauncherPlatform: boolean
      isAppPlatform: boolean
      isWebglPlatform: boolean
    }

export const getDevicePlatform = (): PlatformInformation => {
  const parser = bowser.getParser(window.navigator.userAgent)
  const platform = pickPlatform(parser.getOS().name)

  if (platform === undefined) {
    return {
      platform,
      isLauncherPlatform: false,
      isAppPlatform: false,
      isWebglPlatform: false,
    }
  }

  const isAppPlatform = platform === "Android" || platform === "iOS"
  const isLauncherPlatform = platform === "Windows" || platform === "MacOS"
  const isWebglPlatform = !isAppPlatform

  return {
    platform,
    isLauncherPlatform,
    isAppPlatform,
    isWebglPlatform,
  }
}

const pickPlatform = (
  platform: string | undefined
): DevicePlatform | undefined => {
  // List of possible OS
  // https://github.com/lancedikson/bowser/blob/master/src/constants.js#L93

  switch (platform) {
    case "Windows":
    case "iOS":
    case "Android":
      return platform
    case "macOS":
      return "MacOS"
  }

  console.warn("Platform is unsupported:", platform)
}
