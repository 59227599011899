import type { v1, v3 } from "backoffice-api"
import type { custom } from "src/bonzai/bonzai"
import { DEVICE_PLATFORM } from "src/constants"

type Product = v1["getProduct"]
type ProductType = Product["product_type"]
type ProductStatusV1 = v1["getProductStatus"]["status"]
type ProductProgressV3 = v3["getProductProgress"]["data"]
type ProductStatus = custom["getProductProgress"]

export const getIsInProgress = (
  productType: ProductType,
  status: ProductStatus | undefined
) => {
  if (productType !== "meet") return false

  const hasPlayed = (status?.play_count ?? 0) > 0
  const isCertified = status?.certified ?? false
  return hasPlayed && !isCertified
}

export const getInstallAppLink = (product: Product) => {
  const { platform } = DEVICE_PLATFORM
  const androidLink = product.google_play_test_link || product.google_play_link
  const iosLink = product.test_flight_link || product.apple_store_link

  if (platform === "Android" && androidLink) return androidLink
  if (platform === "iOS" && iosLink) return iosLink
}

export const getIsAttensiProduct = (productType: ProductType) => {
  const types: ProductType[] = [
    "behavior",
    "process",
    "skill",
    "act",
    "operations",
    "workflow",
  ]
  return types.includes(productType)
}

export const getProductLink = (
  productId: number | string,
  productType: ProductType
) => {
  if (getIsAttensiProduct(productType)) {
    return `sim/${productId}`
  }
  if (productType === "activity") {
    return `activity/${productId}`
  }
  if (productType === "meet") {
    return `event/${productId}`
  }
  return `product/${productId}`
}

export const getPreferredUpload = (
  product: Product,
  locale: string | undefined
) => {
  const { uploads, content_link } = product

  const preferredUpload = uploads.find((upload) => upload.locale === locale)

  return preferredUpload?.file_url ?? uploads[0]?.file_url ?? content_link
}

export const getMaxStarsForProduct = (
  productType: ProductType,
  isScorable: boolean
) => {
  if (!isScorable) return 0

  return getIsAttensiProduct(productType) ? 5 : 1
}

export const getIsPlayable = (product: Product) => {
  return product.type.open_with === "launcher"
}

export const getIsMandatory = (
  status: ProductStatusV1 | ProductProgressV3 | undefined
) => {
  if (!status) return false

  return status.mandatory_of === 1 // What is this? Does this make sense?
}
