import type { v1 } from "backoffice-api"
import { DEVICE_PLATFORM } from "src/constants"
import { getInstallAppLink } from "src/dataUtilities/productDataUtilities"
import { IS_APP } from "src/vuplex/constants"

type Product = v1["getProduct"]

export const getPlayCapabilites = (product: Product) => {
  const canStartApp = getCanStartApp(product)
  const canStartLauncher = getCanStartLauncher(product)
  const canInstallApp = getCanInstallApp(product)
  const canInstallWithVpp = getCanInstallWithVpp(product)
  const canPlayWebgl = getCanPlayWebgl(product)

  const canPlay = canPlayWebgl || canStartApp || canStartLauncher

  return {
    canPlay,
    canInstallApp,
    canStartApp,
    canPlayWebgl,
    canStartLauncher,
    canInstallWithVpp,
  }
}

const getCanStartApp = (product: Product): boolean => {
  const { platform, isAppPlatform } = DEVICE_PLATFORM

  return (
    Boolean(product.content_link) &&
    isAppPlatform &&
    isPlatformSupported(product, platform)
  )
}

const getCanStartLauncher = (product: Product): boolean => {
  const { platform, isLauncherPlatform } = DEVICE_PLATFORM

  return (
    Boolean(product.launcher_link) &&
    isLauncherPlatform &&
    isPlatformSupported(product, platform)
  )
}

const getCanInstallApp = (product: Product): boolean => {
  const isInstallHandledByVuplex = IS_APP && getCanStartApp(product)
  if (isInstallHandledByVuplex) return false
  if (getCanInstallWithVpp(product)) return false

  return getInstallAppLink(product) !== undefined
}

const getCanPlayWebgl = (product: Product): boolean => {
  const { isWebglPlatform } = DEVICE_PLATFORM

  return (
    Boolean(product.webgl_content_link) &&
    isWebglPlatform &&
    isPlatformSupported(product, "WebGL")
  )
}

const getCanInstallWithVpp = (product: Product) => {
  const { platform } = DEVICE_PLATFORM
  return product.has_vpp_codes && platform === "iOS"
}

const isPlatformSupported = (product: Product, platform: string) => {
  return product.supported_platforms.some((supportedPlatform) =>
    isPlatformEqual(supportedPlatform, platform)
  )
}

const isPlatformEqual = (left: string, right: string) => {
  return left.toLowerCase().trim() === right.toLowerCase().trim()
}
