import { v1 } from "backoffice-api"
import { useQueryAll } from "bonzai"
import { useQueryParam } from "hooks"
import { leaderboard } from "leaderboard-api"
import { times } from "lodash-es"
import { useTranslation } from "react-i18next"
import { custom } from "src/bonzai/bonzai"
import { getNextPageParamV1 } from "src/bonzai/getNextPageParamV1"
import { useFormatUser } from "src/bonzai/useFormatUser"
import { formatNumber } from "src/dataUtilities/formatNumber"
import { getProductLink } from "src/dataUtilities/productDataUtilities"
import { usePickText } from "src/i18n/usePickText"
import { ConfigError } from "src/tools/ConfigError"
import { LeaderboardViewCompact } from "ui/exports/portal"
import { QueryBoundary, WhenVisible } from "utility-components"

type LeaderboardScope = custom["getLeaderboardScopes"][number]

const PRODUCTS_PER_PAGE = 6

export const LeaderboardViewCompactLoader = () => (
  <LeaderboardViewCompact>
    <QueryBoundary fallback={<LeaderboardSkeletons />}>
      <Load />
    </QueryBoundary>
  </LeaderboardViewCompact>
)

const Load = () => {
  const { scopes, scope, setScope } = useLeaderboardScopes()

  return (
    <>
      <Scopes scopes={scopes} value={scope} onChange={setScope} />
      <QueryBoundary fallback={<LeaderboardSkeletons />}>
        <LeaderboardPages scope={scope} />
      </QueryBoundary>
    </>
  )
}

type ScopesProps = {
  value: string
  onChange: (value: string) => void
  scopes: LeaderboardScope[]
}
const Scopes = ({ scopes, value, onChange }: ScopesProps) => {
  const scopeElements = scopes.map((scope) => (
    <LeaderboardViewCompact.Scope value={scope.value} key={scope.value}>
      {scope.name}
    </LeaderboardViewCompact.Scope>
  ))

  return (
    <LeaderboardViewCompact.ScopeSelect value={value} onChange={onChange}>
      {scopeElements}
    </LeaderboardViewCompact.ScopeSelect>
  )
}

type LeaderboardPagesProps = {
  scope: string
}
const LeaderboardPages = ({ scope }: LeaderboardPagesProps) => {
  const productIdsData = useLeaderboardsData()
  const { data, fetchNextPage, isFetching } = productIdsData

  const productIds = data.pages.flatMap((page) => page.data)
  const leaderboards = productIds.map((productId) => (
    <QueryBoundary fallback={<LeaderboardViewCompact.Skeleton />}>
      <Leaderboard
        key={`product-${productId}`}
        productId={productId}
        scope={scope}
      />
    </QueryBoundary>
  ))

  return (
    <>
      {leaderboards}
      {isFetching && <LeaderboardSkeletons />}
      <WhenVisible
        key={`${scope}-${productIds.length}`}
        whenVisible={fetchNextPage}
      />
    </>
  )
}

type LeaderboardProps = {
  productId: number
  scope: string
}
const Leaderboard = ({ productId, scope }: LeaderboardProps) => {
  const { t, i18n } = useTranslation()
  const [highScores, product] = useLeaderboardData(productId, scope)
  const formatUser = useFormatUser()
  const pickText = usePickText()

  if (highScores.entries.length === 0) return null

  const showMyEntry = highScores.my_entry.value > 0

  const podiumEntries = highScores.entries
    .slice(0, 3)
    .map((entry, index) => (
      <LeaderboardViewCompact.EntryPodium
        key={`podium-${index}`}
        rank={entry.rank}
        avatar={entry.user.image}
        name={formatUser(entry.user)}
        score={formatNumber(i18n.language, entry.value)}
        isFirst={index === 0}
      />
    ))

  const entries = highScores.entries
    .slice(3)
    .map((entry, index) => (
      <LeaderboardViewCompact.Entry
        key={`entry-${index}`}
        rank={entry.rank}
        avatar={entry.user.image}
        name={formatUser(entry.user)}
        score={formatNumber(i18n.language, entry.value)}
        isMe={false}
      />
    ))

  return (
    <LeaderboardViewCompact.Leaderboard>
      <LeaderboardViewCompact.Header
        link={{ to: getProductLink(product.id, product.product_type) }}
        title={pickText(product.titles)}
        linkText={t("leaderboard.SEE_ALL")}
      />
      <LeaderboardViewCompact.Podium>
        {podiumEntries}
      </LeaderboardViewCompact.Podium>
      {entries}
      {showMyEntry && (
        <LeaderboardViewCompact.Entry
          rank={highScores.my_entry.rank}
          avatar={highScores.my_entry.user.image}
          name={formatUser(highScores.my_entry.user)}
          score={formatNumber(i18n.language, highScores.my_entry.value)}
          isMe={true}
        />
      )}
    </LeaderboardViewCompact.Leaderboard>
  )
}

const LeaderboardSkeletons = () => (
  <>
    {times(PRODUCTS_PER_PAGE, () => (
      <LeaderboardViewCompact.Skeleton />
    ))}
  </>
)

const useLeaderboardScopes = () => {
  const scopes = custom.getLeaderboardScopes.useQuery()
  const firstScope = scopes[0]

  if (firstScope === undefined) {
    throw new ConfigError("No leaderboard scopes")
  }

  const [scope = firstScope.value, setScope] = useQueryParam("scope")

  return { scopes, scope, setScope }
}

const useLeaderboardsData = () => {
  return custom.getPlayableProductIds.useInfiniteQuery(
    [{ per_page: PRODUCTS_PER_PAGE }],
    { getNextPageParam: getNextPageParamV1 }
  )
}

const useLeaderboardData = (product_id: number, scope: string) => {
  return useQueryAll(() => [
    leaderboard.getHighScores.useQuery([{ product_id, scope, length: 6 }]),
    v1.getProduct.useQuery([product_id]),
  ])
}
