import { v1 } from "backoffice-api"
import { useQueryAll } from "bonzai"
import { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { custom } from "src/bonzai/bonzai"
import { getIsAttensiProduct } from "src/dataUtilities/productDataUtilities"
import { usePickText } from "src/i18n/usePickText"
import { useBannerStore } from "src/stores/useBannerStore"
import { triggerConfetti } from "src/tools/triggerConfetti"
import { CompletedProductBanner } from "ui/exports/portal"

export const useSyncBannerProductId = (productId: number | string) => {
  const setProductId = useBannerStore((store) => store.setProductId)

  useEffect(() => {
    setProductId(Number(productId))
  }, [productId, setProductId])
}

export const CompletedProductBannerLoader = () => {
  const { t } = useTranslation()
  const { productId, isVisible } = useBannerStore()
  const [product, status] = useData(productId)
  const pickText = usePickText()

  useHideAfterTimeout()
  useWatchScore(productId, status?.high_score)

  if (product === undefined || status === undefined) return null

  const isSimProduct = getIsAttensiProduct(product.product_type)

  const label = isSimProduct
    ? `${status.high_score} ${t("winningBox.PRODUCT_SIM_SCORE")}`
    : t("winningBox.PRODUCT_NONSIM_COMPLETED")

  return (
    <CompletedProductBanner
      isVisible={isVisible}
      title={pickText(product.titles)}
      label={label}
      variant={isSimProduct ? "points" : "completed"}
    />
  )
}

const useData = (productId: number | undefined) => {
  return useQueryAll(() => [
    v1.getProduct.useQuery([productId!], {
      enabled: productId !== undefined,
      suspense: false,
    }),

    custom.getProductProgress.useQuery([productId!, { apiVersion: "v1" }], {
      refetchOnWindowFocus: "always",
      suspense: false,
      enabled: productId !== undefined,
    }),
  ])
}

const useWatchScore = (
  productId: number | undefined,
  score: number | undefined
) => {
  const { setIsVisible } = useBannerStore()
  const ref = useRef({ productId, score })

  useEffect(() => {
    const { productId: previousProductId, score: previousScore } = ref.current
    ref.current = { productId, score }

    if (score === undefined || previousScore === undefined) return

    const productHasChanged = productId !== previousProductId
    if (productHasChanged) return

    if (score > previousScore) {
      triggerConfetti()
      setIsVisible(true)
    }
  }, [productId, score, setIsVisible])
}

const useHideAfterTimeout = () => {
  const { isVisible, setIsVisible } = useBannerStore()

  useEffect(() => {
    if (!isVisible) return

    const timeout = setTimeout(() => {
      setIsVisible(false)
    }, 3000)

    return () => clearTimeout(timeout)
  }, [isVisible, setIsVisible])
}
